import { Link } from "react-router-dom";
import notFoundImage from "../assets/img/not-found.png"; // Asumsi kamu punya gambar untuk halaman 404

const NotFound = () => {
  return (
    <main className="h-screen flex flex-col items-center justify-center text-center bg-gray-100 p-4">
      <div className="flex flex-col items-center">
        <img
          src={notFoundImage}
          alt="404 Not Found"
          className="w-64 md:w-80 mb-8 pointer-events-none"
        />
        <h1 className="text-3xl font-bold text-primary mb-4">
          Oops! Halaman Tidak Ditemukan
        </h1>
        <p className="text-lg text-gray-600 mb-6">
          Kami tidak dapat menemukan halaman yang Anda cari. Silakan kembali ke
          halaman utama.
        </p>
        <Link
          to="/"
          className="bg-primary text-white px-4 py-2 rounded-lg text-base hover:opacity-90 transition duration-300"
        >
          Kembali ke Halaman Utama
        </Link>
      </div>
    </main>
  );
};

export default NotFound;
