import React, { useState, useEffect, useCallback } from "react";
import { auth, db } from "../config/firebaseConfig";
import {
  doc,
  updateDoc,
  getDoc,
  addDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Question from "./Question";
import Sidebar from "./Sidebar";
import { signOut } from "firebase/auth";

const Quizfinal = () => {
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5400);
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [finalquestions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(0);

  const handleQuestionClick = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleAnswerCorrect = (questionId, selectedOption) => {
    const updatedAnswers = {
      ...userAnswers,
      [questionId]: selectedOption,
    };

    setUserAnswers(updatedAnswers);

    // Save updated answers to local storage
    localStorage.setItem("userAnswers", JSON.stringify(updatedAnswers));

    const currentQuestion = finalquestions[currentQuestionIndex];
    if (currentQuestion.correctAnswer === selectedOption) {
      setCorrectAnswers((prevCount) => prevCount + 1);
      setScore((prevScore) => prevScore + currentQuestion.weight); // Add the weight to the score
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < finalquestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const updateUserStatus = async (userId, status) => {
    const userRef = doc(db, "finalparticipants", userId);
    await updateDoc(userRef, { status });
  };

  // Updated handleSubmit function with score calculation
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const userId = auth.currentUser.uid;

      // Get participant data to retrieve the name
      const participantRef = doc(db, "finalparticipants", userId);
      const participantSnap = await getDoc(participantRef);
      const participantData = participantSnap.data();

      // Simpan hasil kuis ke Firestore dengan score
      await addDoc(collection(db, "finalresults"), {
        userId,
        name: participantData.name,
        email: auth.currentUser.email,
        correctAnswers,
        score,
        timestamp: new Date(),
      });

      // Update user status to "Selesai"
      await updateUserStatus(userId, "Selesai");

      // Hapus data localStorage terkait kuis
      localStorage.removeItem("quizStartTime");
      localStorage.removeItem("userAnswers");

      // Set status submitted
      setSubmitted(true);

      // Logout user setelah menyelesaikan kuis
      await signOut(auth);

      setLoading(false);

      // Redirect ke halaman hasil
      navigate("/olimpiade-komputer/final/result");
    } catch (error) {
      console.error("Error submitting finalresults: ", error);
      setLoading(false);
    }
  }, [correctAnswers, score, navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "finalquestions"));
        let fetchedQuestions = [];
        querySnapshot.forEach((doc) => {
          fetchedQuestions.push({ id: doc.id, ...doc.data() });
        });

        // Check if question order is already saved in localStorage
        let questionOrder = JSON.parse(localStorage.getItem("questionOrder"));
        if (!questionOrder) {
          // Shuffle the finalquestions and save the order in localStorage
          questionOrder = fetchedQuestions
            .map((question) => question.id)
            .sort(() => Math.random() - 0.5);
          localStorage.setItem("questionOrder", JSON.stringify(questionOrder));
        }

        // Arrange finalquestions according to the saved order
        fetchedQuestions = questionOrder.map((id) =>
          fetchedQuestions.find((q) => q.id === id)
        );

        setQuestions(fetchedQuestions);
        setLoadingQuestions(false);

        // Retrieve saved answers from localStorage and update state
        const savedAnswers = JSON.parse(localStorage.getItem("userAnswers"));
        if (savedAnswers) {
          setUserAnswers(savedAnswers);
        }
      } catch (error) {
        console.error("Error fetching finalquestions: ", error);
        setLoadingQuestions(false);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const savedStartTime = localStorage.getItem("quizStartTime");
    const currentTime = Date.now();

    if (savedStartTime) {
      const timeElapsed = Math.floor((currentTime - savedStartTime) / 1000);
      setTimeLeft(Math.max(5400 - timeElapsed, 0));
    } else {
      localStorage.setItem("quizStartTime", currentTime);
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      localStorage.removeItem("quizStartTime");
      localStorage.removeItem("userAnswers");
      handleSubmit();
    }
  }, [timeLeft, handleSubmit]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="quiz-container flex pt-10 select-none z-10">
      {!loadingQuestions && (
        <Sidebar
          finalquestions={finalquestions}
          currentQuestionIndex={currentQuestionIndex}
          handleQuestionClick={handleQuestionClick}
          userAnswers={userAnswers}
          formatTime={formatTime}
          timeLeft={timeLeft}
          handleSubmit={handleSubmit}
          submitted={submitted}
          loading={loading}
        />
      )}

      <div className="flex-1 pl-20 ml-64 z-0">
        <div className="relative pt-5">
          <h1 className="text-2xl font-bold text-primary mb-5 text-center">
            Quiz Olimpiade Komputer
          </h1>

          <p className="text-sm font-bold text-secondary mb-3">
            Silahkan jawab pertanyaan berikut dengan baik dan benar
          </p>

          {!loadingQuestions && finalquestions.length > 0 ? (
            <Question
              key={finalquestions[currentQuestionIndex].id}
              question={finalquestions[currentQuestionIndex]}
              onAnswerCorrect={handleAnswerCorrect}
              userAnswer={userAnswers[finalquestions[currentQuestionIndex].id]} // Ensure the selected answer is restored
            />
          ) : (
            <p>Loading...</p>
          )}

          {/* Button container with flex */}
          <div className="flex justify-between mt-4">
            {/* Spacer to push Selanjutnya to the right */}
            <div className="flex-1">
              {!submitted && currentQuestionIndex > 0 && (
                <button
                  onClick={() =>
                    setCurrentQuestionIndex(currentQuestionIndex - 1)
                  }
                  className="bg-primary text-white p-2 rounded"
                >
                  Sebelumnya
                </button>
              )}
            </div>

            {!submitted && currentQuestionIndex < finalquestions.length - 1 && (
              <button
                onClick={handleNextQuestion}
                className="bg-primary text-white p-2 rounded"
              >
                Selanjutnya
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quizfinal;
