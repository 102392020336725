import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import events from "../../data/events";
import fotoPemateri from "../../assets/img/PemateriTlb24.jpg";
import dok1 from "../../assets/img/dokumentasi/tlb24/1.jpg";
import dok2 from "../../assets/img/dokumentasi/tlb24/2.jpg";
import dok3 from "../../assets/img/dokumentasi/tlb24/3.jpg";
import dok4 from "../../assets/img/dokumentasi/tlb24/4.jpg";
import dok5 from "../../assets/img/dokumentasi/tlb24/5.jpg";
import dok6 from "../../assets/img/dokumentasi/tlb24/6.jpg";
import dok7 from "../../assets/img/dokumentasi/tlb24/7.jpg";
import dok8 from "../../assets/img/dokumentasi/tlb24/8.jpg";

const Tlb24 = () => {
  const link = useLocation().pathname.split("/").pop();
  const [data, setData] = useState({
    title: "",
    detail: "",
    date: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const event = events.find((item) => item.link === link);
    if (event) setData(event);
  }, [link]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeImage = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  const dokumentasiImages = [dok1, dok2, dok3, dok4, dok5, dok6, dok7, dok8];

  return (
    <main>
      <div className="flex flex-col pt-16 md:pt-20 md:mt-16 items-center">
        <p className="text-2xl mt-6 font-bold text-center text-primary md:text-4xl">
          {data.title}
        </p>
        <p className="text-sm font-bold text-center text-secondary mt-2 md:text-lg">
          <i className="fa-regular fa-calendar mr-2" />
          {new Date(data.date).toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>
      <p className="text-center py-5 text-primary mx-2 text-sm md:text-lg">
        {data.detail}
      </p>
      <p className="text-center pb-10">
        <i>
          “Machine Learning Sebagai Pilar Utama Dalam Transformasi Digital:
          Implementasi Pada Solusi Inovatif”
        </i>
      </p>
      <div className="text-center py-5 text-primary mx-2 text-sm md:text-lg">
        <p>
          Acara pukul <strong>08.30 - 12.00 WIB</strong>
        </p>
        <p>Aula UPT TIK Universitas Syiah kuala</p>
        <p>
          Jl. Syech Abdurrauf No.10, Kopelma Darussalam, Syiah Kuala, Banda Aceh
          City, Aceh 23111
        </p>
      </div>
      <div className="mx-3 md:mx-0">
        <div className="flex items-center bg-white md:w-2/3 w-full justify-center mx-auto my-3 border rounded-full p-3 shadow-md">
          <img
            src={fotoPemateri}
            className="rounded-full pointer-events-none select-none"
            alt="Foto Pemateri"
            width={100}
            height={100}
          />
          <div className="flex flex-col ml-4">
            <p className="font-bold text-2xl text-primary">Pemateri</p>
            <p className="text-left md:text-center text-sm md:text-lg">
              Yudha Nurdin, S.T., M.T.
            </p>
          </div>
        </div>
        <div className="bg-white p-8 rounded-xl shadow-xl mt-10">
          <h6 className="font-bold text-2xl text-primary mb-6">Dokumentasi</h6>
          <div className="grid-container grid grid-cols-2 md:grid-cols-4 gap-4">
            {dokumentasiImages.map((image, index) => (
              <div
                key={index}
                className="cursor-pointer hover:scale-105 transition duration-300"
                onClick={() => handleImageClick(image)}
              >
                <img
                  src={image}
                  alt={`Dokumentasi ${index + 1}`}
                  className="w-full h-48 object-cover rounded-md"
                />
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
            onClick={closeImage}
          >
            <div
              className="relative max-w-3xl w-full mx-4 transition duration-300 transform overflow-hidden"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="absolute top-4 right-4 text-white text-3xl"
                onClick={closeImage}
              >
                &times;
              </button>
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full h-auto object-contain rounded-md"
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default Tlb24;
