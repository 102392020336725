import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDy1PfSOS5Ei2RZJb4SccZlIvDeGGIfjMY",
  authDomain: "cmd-webs.firebaseapp.com",
  projectId: "cmd-webs",
  storageBucket: "cmd-webs.appspot.com",
  messagingSenderId: "392071868403",
  appId: "1:392071868403:web:8a721d8fc4b0b0e4b72c86",
  measurementId: "G-95EQGXG0ZP",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);



export { auth, db, storage };
