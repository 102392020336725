import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./config/firebaseConfig";
import cmdLogo from "../../../assets/img/cmdlogo.png";
import {
  doc,
  setDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const checkQuizCompletion = async (userId) => {
    const resultsRef = collection(db, "results");
    const q = query(resultsRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  };

  const updateUserStatus = async (userId, status) => {
    const userRef = doc(db, "participants", userId);
    await setDoc(userRef, { status }, { merge: true });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      // Sign in the user
      await signInWithEmailAndPassword(auth, email, password);

      const userId = auth.currentUser.uid;

      // Check if the user has already completed the quiz
      const hasCompletedQuiz = await checkQuizCompletion(userId);

      // Redirect based on quiz completion status
      if (hasCompletedQuiz) {
        window.location.href = "/olimpiade-komputer/result";
      } else {
        await updateUserStatus(userId, "Sedang Mengerjakan");
        window.location.href = "/olimpiade-komputer/quiz";
      }
    } catch (error) {
      handleAuthError(error);
    }
  };

  const handleAuthError = (error) => {
    switch (error.code) {
      case "auth/invalid-email":
        setError("Email format tidak valid.");
        break;
      case "auth/user-disabled":
        setError("Akun ini telah dinonaktifkan.");
        break;
      case "auth/user-not-found":
        setError("Pengguna dengan email ini tidak ditemukan.");
        break;
      case "auth/wrong-password":
        setError("Password salah. Silakan coba lagi.");
        break;
      default:
        setError("Terjadi kesalahan. Silakan coba lagi nanti.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 via-blue-200 to-blue-300">
      <form
        onSubmit={handleLogin}
        className="bg-white p-10 rounded-xl shadow-2xl w-full max-w-lg"
      >
        <img src={cmdLogo} alt="logo" className="mb-6 w-24 mx-auto" />
        <h2 className="text-3xl font-semibold text-primary text-center mb-2">
          Login
        </h2>
        <h4 className="text-xl text-primary text-center mb-8">
          Olimpiade Komputer CMD 2024
        </h4>
        {error && (
          <p className="text-red-500 text-center mb-4 font-semibold">{error}</p>
        )}
        <div className="mb-6">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-300 p-3 w-full rounded-md focus:outline-none focus:border-primary transition-colors"
            placeholder="Email"
            required
          />
        </div>
        <div className="mb-6 relative">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="border border-gray-300 p-3 w-full rounded-md focus:outline-none focus:border-primary transition-colors pr-12"
            placeholder="Password"
            required
          />
          <i
            className={`fas ${
              showPassword ? "fa-eye-slash" : "fa-eye"
            } absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-600 hover:text-primary transition-colors`}
            onClick={togglePasswordVisibility}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className="bg-primary text-white py-3 rounded-md w-full hover:bg-primary-dark transition duration-300 transform hover:-translate-y-1 shadow-lg"
        >
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default Login;
