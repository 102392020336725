import React, { useState, useEffect } from "react";
import { db, storage } from "./config/firebaseConfig"; // Tambahkan storage dari firebaseConfig
import { collection, addDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Tambahkan untuk storage

const AddQuestion = () => {
  const [question, setQuestion] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [optionC, setOptionC] = useState("");
  const [optionD, setOptionD] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [weight] = useState(2);
  const [questionCount, setQuestionCount] = useState(0);
  const [image, setImage] = useState(null); // State untuk gambar
  const [imagePosition, setImagePosition] = useState(""); // Posisi gambar
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchQuestionCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "questions"));
        setQuestionCount(querySnapshot.size);
      } catch (error) {
        console.error("Error fetching question count: ", error);
      }
    };

    fetchQuestionCount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (
      !question ||
      !optionA ||
      !optionB ||
      !optionC ||
      !optionD ||
      !correctAnswer ||
      !weight
    ) {
      alert("Please fill out all fields and select the correct answer.");
      return;
    }

    const weightInt = parseInt(weight, 10);

    if (isNaN(weightInt) || weightInt < 0) {
      alert("Please enter a valid weight (non-negative integer).");
      return;
    }

    let uploadedImageUrl = "";
    // Upload gambar jika ada
    if (image) {
      const imageRef = ref(storage, `questions/${Date.now()}_${image.name}`);
      try {
        const snapshot = await uploadBytes(imageRef, image);
        uploadedImageUrl = await getDownloadURL(snapshot.ref); // Ambil URL gambar
      } catch (error) {
        console.error("Error uploading image: ", error);
        alert("Failed to upload image.");
        return;
      }
    }

    try {
      await addDoc(collection(db, "questions"), {
        question,
        options: { A: optionA, B: optionB, C: optionC, D: optionD },
        correctAnswer,
        weight,
        imageUrl: uploadedImageUrl, // Simpan URL gambar jika ada
        imagePosition: imagePosition || null, // Simpan posisi gambar jika dipilih
      });
      alert("Question added successfully!");
      setQuestion("");
      setOptionA("");
      setOptionB("");
      setOptionC("");
      setOptionD("");
      setCorrectAnswer("");
      setImage(null);
      setImagePosition("");
      setQuestionCount((prevCount) => prevCount + 1);
      console.log(question);
    } catch (error) {
      console.error("Error adding question: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center text-primary mb-6">
        Add New Question
      </h2>
      <p className="text-center text-gray-700 mb-4">
        Soal yang sudah ada: {questionCount}
      </p>
      <form onSubmit={handleSubmit}>
        {/* Input Question */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="question"
          >
            Question
          </label>
          <textarea
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        {/* Option A */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionA"
          >
            Option A
          </label>
          <input
            id="optionA"
            type="text"
            value={optionA}
            onChange={(e) => setOptionA(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        {/* Option B */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionB"
          >
            Option B
          </label>
          <input
            id="optionB"
            type="text"
            value={optionB}
            onChange={(e) => setOptionB(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        {/* Option C */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionC"
          >
            Option C
          </label>
          <input
            id="optionC"
            type="text"
            value={optionC}
            onChange={(e) => setOptionC(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        {/* Option D */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionD"
          >
            Option D
          </label>
          <input
            id="optionD"
            type="text"
            value={optionD}
            onChange={(e) => setOptionD(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Correct Answer
          </label>
          <div className="flex justify-around">
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="A"
                checked={correctAnswer === "A"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              A
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="B"
                checked={correctAnswer === "B"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              B
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="C"
                checked={correctAnswer === "C"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              C
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="D"
                checked={correctAnswer === "D"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              D
            </label>
          </div>
        </div>
        <div className="mb-4 hidden">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="weight"
          >
            Weight
          </label>
          <input
            id="weight"
            type="number"
            step="1"
            min="0"
            value={weight}
            className="w-full px-3 py-2 border rounded-lg "
          />
        </div>

        {/* Input Gambar */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="image"
          >
            Upload Image (optional)
          </label>
          <input
            id="image"
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>

        {/* Opsi Posisi Gambar */}
        {image && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Image Position
            </label>
            <div className="flex justify-around">
              <label>
                <input
                  type="radio"
                  name="imagePosition"
                  value="top"
                  checked={imagePosition === "top"}
                  onChange={(e) => setImagePosition(e.target.value)}
                  className="mr-2"
                />
                Top
              </label>
              <label>
                <input
                  type="radio"
                  name="imagePosition"
                  value="bottom"
                  checked={imagePosition === "bottom"}
                  onChange={(e) => setImagePosition(e.target.value)}
                  className="mr-2"
                />
                Bottom
              </label>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className={`w-full p-2 rounded-lg transition duration-300 ${
            isSubmitting
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-primary text-white hover:bg-primary-dark"
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Adding..." : "Add Question"}
        </button>
      </form>
    </div>
  );
};

export default AddQuestion;
