import { useEffect } from "react";

const InstagramEmbed = ({ url, width = 540, height = 700 }) => {
  useEffect(() => {
    // Load Instagram embed script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = "//www.instagram.com/embed.js";
    script.onload = () => {
      window.instgrm.Embeds.process(); // Process the embed script
    };
    document.body.appendChild(script);

    // Clean up the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={url}
      data-instgrm-version="14"
      style={{
        background: "#FFF",
        border: "0",
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
        margin: "1px",
        maxWidth: `${width}px`, // Use dynamic width
        height: `${height}px`, // Use dynamic heigh
        padding: "0",
        width: "calc(100% - 2px)",
      }}
    >
      <div style={{ padding: "16px" }}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          View on Instagram
        </a>
      </div>
    </blockquote>
  );
};

export default InstagramEmbed;
