import React from "react";

const Question = ({ question, onAnswerCorrect, userAnswer }) => {
  const handleAnswerChange = (selectedOption) => {
    onAnswerCorrect(question.id, selectedOption);
  };

  if (!question) {
    return <p>Loading...</p>;
  }

  const optionLabels = ["A", "B", "C", "D"];

  // Function to render the image if it exists
  const renderImage = () => {
    if (question.imageUrl) {
      return (
        <div className="my-4 flex justify-center">
          <img
            src={question.imageUrl}
            alt="Question-related"
            className="max-w-full pointer-events-none max-h-64 object-contain"
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 z-10">
      {/* Render the image at the top if the imagePosition is 'top' */}
      {question.imagePosition === "top" && renderImage()}

      <p
        className="font-medium text-lg mb-2"
        dangerouslySetInnerHTML={{
          __html: question.question.replace(/\n/g, "<br />"),
        }}
      >
        {/* {question.question} */}
      </p>

      {/* Render the image at the bottom if the imagePosition is 'bottom' */}
      {question.imagePosition === "bottom" && renderImage()}

      {Object.entries(question.options).map(([key, option], idx) => (
        <div key={key} className="flex items-center mb-1">
          <input
            type="radio"
            id={`${question.id}-${key}`}
            name={question.id}
            value={key}
            onChange={() => handleAnswerChange(key)}
            className="mr-2"
            checked={userAnswer === key}
          />
          <label htmlFor={`${question.id}-${key}`} className="text-gray-700">
            <span className="font-semibold">{optionLabels[idx]}.</span> {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Question;
