import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Layout from "./container/layout";
import Profil from "./pages/profil";
import Acara from "./pages/acara";
import Berita from "./pages/berita";
import Galeri from "./pages/galeri";
import Layanan from "./pages/layanan";
import Cmd from "./pages/events/cmd";
import Babak1 from "./pages/events/fast-typing/babak1";
import Babak2 from "./pages/events/fast-typing/babak2";
import Final from "./pages/events/fast-typing/final";
import Login from "./pages/events/olimpiade-komputer/Login";
import Quiz from "./pages/events/olimpiade-komputer/Quiz";
import Result from "./pages/events/olimpiade-komputer/Result";
import ProtectedRoute from "./pages/events/olimpiade-komputer/config/ProtectedRoute";
import AddQuestion from "./pages/events/olimpiade-komputer/addQuestion";
import NotFound from "./pages/notfound"; // Halaman 404
import AdminPage from "./pages/events/olimpiade-komputer/adminPage";
import Biner80 from "./pages/events/biner80";
import Loginfinal from "./pages/events/olimpiade-komputer/final/Login";
import AdminPagefinal from "./pages/events/olimpiade-komputer/final/adminPage";
import Resultfinal from "./pages/events/olimpiade-komputer/final/Result";
import AddQuestionFinal from "./pages/events/olimpiade-komputer/final/addQuestion";
import Quizfinal from "./pages/events/olimpiade-komputer/final/Quiz";
import Tlb24 from "./pages/events/tlb24";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration (optional)
      once: true, // whether animation should happen only once
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {/* LINK */}
          <Route path="/" element={<Navigate to="/profil" replace />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/acara" element={<Acara />} />
          <Route path="/berita" element={<Berita />} />
          <Route path="/galeri" element={<Galeri />} />
          <Route path="/layanan" element={<Layanan />} />
          {/* SUB-LINK */}
          <Route path="/acara/cmd2024" element={<Cmd />} />
          <Route path="/acara/biner80" element={<Biner80 />} />
          <Route path="/acara/tlb24" element={<Tlb24 />} />
          <Route path="/acara/cmd/fast-typing/babak1" element={<Babak1 />} />
          <Route path="/acara/cmd/fast-typing/babak2" element={<Babak2 />} />
          <Route path="/acara/cmd/fast-typing/final" element={<Final />} />
          <Route path="/olimpiade-komputer/login" element={<Login />} />
          <Route path="/olimpiade-komputer/admin" element={<AdminPage />} />
          <Route
            path="/olimpiade-komputer/tambahsoal"
            element={<AddQuestion />}
          />
          <Route
            path="/olimpiade-komputer/quiz"
            element={
              <ProtectedRoute>
                <Quiz />
              </ProtectedRoute>
            }
          />
          <Route
            path="/olimpiade-komputer/final/login"
            element={<Loginfinal />}
          />
          <Route
            path="/olimpiade-komputer/final/admin"
            element={<AdminPagefinal />}
          />
          <Route
            path="/olimpiade-komputer/final/tambahsoal"
            element={<AddQuestionFinal />}
          />
          <Route
            path="/olimpiade-komputer/final/quiz"
            element={
              <ProtectedRoute>
                <Quizfinal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/olimpiade-komputer/final/result"
            element={<Resultfinal />}
          />
          <Route path="/olimpiade-komputer/result" element={<Result />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
