import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert

const Sidebarfinal = ({
  finalquestions,
  currentQuestionIndex,
  handleQuestionClick,
  userAnswers,
  formatTime,
  timeLeft,
  handleSubmit,
  submitted,
  loading,
}) => {
  const navigate = useNavigate();
  const handleOpenModal = () => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyelesaikan soal ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3C617C",
      cancelButtonColor: "#575C5F",
      confirmButtonText: "Selesai",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
        navigate("/olimpiade-komputer/final/result");
      }
    });
  };

  return (
    <div className="sidebar fixed top-0 left-0 bg-white shadow-md w-64 h-screen p-4 flex flex-col justify-between">
      {/* Question Numbers */}
      <div className="mb-4">
        <h2 className="font-bold mb-4 text-center">Nomor Soal</h2>
        <div className="grid grid-cols-5 gap-2">
          {finalquestions.map((finalquestion, index) => (
            <button
              key={finalquestion.id}
              className={`block text-center p-2 rounded ${
                index === currentQuestionIndex
                  ? "bg-slate-400 text-white"
                  : userAnswers[finalquestion.id]
                  ? "bg-primary text-white"
                  : "bg-gray-100"
              }`}
              onClick={() => handleQuestionClick(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div className="bg-gray-200 text-gray-700 p-2 rounded text-center mt-4">
          Waktu Tersisa: {formatTime(timeLeft)}
        </div>
      </div>

      {/* Submit button */}
      <div className="mt-auto">
        {!submitted ? (
          <button
            onClick={handleOpenModal}
            className={`bg-primary text-white p-2 rounded w-full ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Mengirim..." : "Selesai"}
          </button>
        ) : (
          <p className="text-center">
            Terima kasih, jawaban Anda sudah tersimpan!
          </p>
        )}
      </div>
    </div>
  );
};

export default Sidebarfinal;
