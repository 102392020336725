const events = [
  {
    id: 1,
    title: "CMD (Computer Multi-Challenge Day)",
    detail:
      "Computer Multi-Challenge Day merupakan kegiatan tahunan dari divisi PSDM HIMATEKKOM USK. Kegiatan ini meliputi kegiatan seminar nasional disertai dengan perlombaan seperti MLBB Tournament, Karya Tulis Ilmiah, Fast Typing, dan Desain Poster.",
    date: new Date("2022-12-01"),
    endDate: new Date("2022-12-03"),
    link: "",
  },
  {
    id: 2,
    title: "Tekkom Learning Bootcamp 2024",
    detail:
      "Tekkom Learning Bootcamp adalah kegiatan tahunan yang diselenggarakan oleh divisi PSDM HIMATEKKOM USK. Kegiatan ini berupa kegiatan belajar secara rutin selama 5 hari yang pada kali ini mempelajari mengenai Machine Learning dan diakhiri dengan projek akhir perkelompok.",
    date: new Date("2024-08-21"),
    endDate: new Date("2024-08-24"),
    link: "tlb24",
  },
  {
    id: 3,
    title: "Biner 8.0",
    detail:
      "Biner (Bina Islami Aneuk Komputer) adalah kegiatan tahunan yang diselenggarakan oleh divisi Kerohanian HIMATEKKOM USK. Kegiatan ini merupakan sebuah rangkaian acara penyambutan mahasiswa baru program Studi Teknik Komputer di Universitas Syiah Kuala.",
    date: new Date("2024-10-20"),
    // endDate: new Date("2024-11-21"),
    link: "biner80",
  },
  {
    id: 4,
    title: "CMD (Computer Multi-Challenge Day) 2024",
    detail:
      "Computer Multi-Challenge Day adalah acara yang menghubungkan mahasiswa, perguruan tinggi, industri, dan pemerintah untuk menciptakan solusi inovatif di bidang teknologi dan ilmu komputer. Melalui perlombaan dan seminar nasional, acara ini mendorong mahasiswa untuk berpikir kreatif, mengasah keterampilan, dan berkontribusi dalam membangun masa depan yang lebih cerdas dan berkelanjutan di Indonesia.",
    date: new Date("2024-10-31"),
    endDate: new Date("2024-11-03"),
    link: "cmd2024",
  },
];

export default events;
