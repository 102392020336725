import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import events from "../../data/events";
import hackathon from "../../assets/img/cmd/hackathon.png";
import uiux from "../../assets/img/cmd/uiux.png";
import olimpkom from "../../assets/img/cmd/olimpkom.png";
import seminar from "../../assets/img/cmd/seminar2.png";
import workshop from "../../assets/img/cmd/seminar2.png";
import combat from "../../assets/img/cmd/combat.png";

const Cmd = () => {
  const link = useLocation().pathname.split("/").pop();
  const [data, setData] = useState({
    title: "",
    detail: "",
    date: "",
  });

  useEffect(() => {
    const event = events.filter((item) => item.link === link)[0];
    setData(event);
  }, [link]);

  const handleRegisterClick = (registerLink, title) => {
    if (!registerLink) {
      Swal.fire({
        icon: "info",
        title: "Pendaftaran Belum Dibuka",
        text: "Mohon maaf, pendaftaran " + title + " belum dibuka.",
        confirmButtonText: "OK",
        backgroundColor: "#f8f9fa",
        color: "#212529",
        confirmButtonColor: "#3C617C",
      });
    } else {
      window.open(registerLink, "_blank");
    }
  };

  return (
    <main>
      {/* Konten Event */}
      <div className="flex flex-col pt-16  md:pt-20 md:mt-16 items-center ">
        <p className="text-2xl mt-6 font-bold text-center text-primary md:text-4xl">
          {data.title}
        </p>
        <p className="text-sm font-bold text-center text-secondary mt-2 md:text-lg">
          <i className="fa-regular fa-calendar mr-2" />
          31 - 03 November 2024
        </p>
      </div>
      <p className="text-center py-5 text-primary mx-2 text-sm md:text-lg">
        {data.detail}
      </p>
      <p className="text-center pb-10">
        <i>“Inovasi Teknologi untuk Masa Depan Berkelanjutan”</i>
      </p>
      <div className="w-12/12 flex items-center justify-center gap-4 flex-wrap">
        {[
          {
            image: hackathon,
            title: "Syiah Kuala Hackathon",
            cost: "50K/Tim",
            registerLink: "https://forms.gle/L6EqXR1Sm4vXBUs79",
            guideLink:
              "https://drive.google.com/file/d/1wQ1A8QK2lcPJ-TPRJ-iM8Cn86jnHNzI4/view?usp=sharing",
            templateLink:
              "https://drive.google.com/uc?export=download&id=1n_8QaYvcdDVht1bThXS5ixq1VVxlhTv8",
          },
          {
            image: uiux,
            title: "UI/UX Competition",
            cost: "50K/Tim",
            registerLink: "https://forms.gle/ndcN1NfZVnnLH9vk8",
            guideLink:
              "https://drive.google.com/file/d/1SQ_nB3ZqqWYahyIGQBwu0hQsp6AWnOHO/view?usp=drive_link",
            templateLink:
              "https://drive.google.com/uc?export=download&id=1_R4_rsN9JMmwOagMZQdtWJtvkJqgaK6D",
          },
          {
            image: olimpkom,
            title: "Olimpiade Komputer",
            cost: "20K/Orang",
            registerLink:
              "https://docs.google.com/forms/d/e/1FAIpQLSckUgMmGOpJ8QafX9StWCEOVtInf-wUuhkJtBfZPVwCvH2k1Q/viewform",
            guideLink:
              "https://drive.google.com/file/d/1jZGoBmEUe2LNRHed6tx5SnQ-JTELKhJ4/view?usp=drive_link",
          },
          {
            image: combat,
            title: "Combat Tournament",
            cost: "50K/Tim",
            registerLink: "",
            guideLink:
              "https://drive.google.com/file/d/1OkbvE8duWv-gO5j-o7Vyk3q_KRWhTXbF/view?usp=drive_link",
          },
          {
            image: seminar,
            title: "Seminar Internasional",
            cost: "10K/Orang",
            registerLink: "https://forms.gle/pioC2rUk4NzwDAbp9",
            guideLink: "",
          },
          {
            image: workshop,
            title: "Workshop Design",
            cost: "10K/Orang",
            registerLink: "",
            guideLink: "",
          },
        ].map((event, index) => (
          <div
            key={index}
            className="p-4 my-4 md:p-8 flex items-center flex-col w-11/12 md:w-5/12 shadow-lg rounded-2xl bg-[white] min-h-[20rem]"
          >
            <img
              style={{ maxHeight: "15rem" }}
              alt={event.title.toLowerCase().replace(" ", "-")}
              src={event.image}
              className="pointer-events-none"
            />
            <p className="font-bold pt-4">{event.title}</p>
            <p className="text-secondary pb-4 text-sm">
              Biaya pendaftaran: <strong>{event.cost}</strong>
            </p>
            <div className="w-full flex flex-col gap-2 mt-auto">
              <button
                onClick={() =>
                  handleRegisterClick(event.registerLink, event.title)
                }
                className="bg-blue shadow-md hover:opacity-80 transition-all duration-300 text-[white] w-full py-2 rounded-lg"
              >
                <i className="fa-solid fa-pen-clip mr-2" />
                Daftar
              </button>

              {/* Tombol Lihat Juknis */}
              <button
                onClick={() =>
                  event.guideLink && window.open(event.guideLink, "_blank")
                }
                className={`${
                  event.guideLink
                    ? "bg-primary shadow-md hover:opacity-80"
                    : "hidden bg-gray-300 cursor-default opacity-0"
                } transition-all duration-300 text-[white] w-full py-2 rounded-lg`}
                disabled={!event.guideLink}
              >
                <i className="fa-regular fa-file-lines mr-2" />
                Petunjuk Teknis
              </button>
              <button
                onClick={() =>
                  event.templateLink &&
                  window.open(event.templateLink, "_blank")
                }
                className={`${
                  event.templateLink
                    ? "bg-orange-400 shadow-md hover:opacity-80"
                    : "hidden bg-gray-300 cursor-default opacity-0"
                } transition-all duration-300 text-[white] w-full py-2 rounded-lg`}
                disabled={!event.templateLink}
              >
                <i className="fa-solid fa-download mr-2" />
                Download Template
              </button>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
};

export default Cmd;
