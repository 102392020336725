import React, { useState, useEffect } from "react";
import { db } from "./config/firebaseConfig";
import {
  collection,
  onSnapshot,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  query,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";

const AdminPage = () => {
  const [participants, setParticipants] = useState([]);
  const [results, setResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Search states
  const [participantSearch, setParticipantSearch] = useState("");
  const [resultSearch, setResultSearch] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [isScoreVisible, setIsScoreVisible] = useState(true);
  const [sortOption, setSortOption] = useState("name");

  useEffect(() => {
    // Realtime listener for participants
    const unsubscribeParticipants = onSnapshot(
      query(collection(db, "participants")),
      (snapshot) => {
        const participantList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParticipants(participantList);
      },
      (error) => {
        console.error("Error fetching participants: ", error);
      }
    );

    // Realtime listener for results
    const unsubscribeResults = onSnapshot(
      query(collection(db, "results")),
      (snapshot) => {
        const resultList = snapshot.docs.map((doc) => doc.data());
        setResults(resultList);
      },
      (error) => {
        console.error("Error fetching results: ", error);
      }
    );

    // Cleanup listeners on component unmount
    return () => {
      unsubscribeParticipants();
      unsubscribeResults();
    };
  }, []); // Empty dependency array as we want this to run only once on mount

  useEffect(() => {
    fetchData();
  }, []);

  const filteredParticipants = [...participants]
    .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
    .filter((participant) => {
      const searchTerm = participantSearch.toLowerCase();
      return (
        participant.name.toLowerCase().includes(searchTerm) ||
        participant.email.toLowerCase().includes(searchTerm) ||
        participant.status.toLowerCase().includes(searchTerm)
      );
    });

  const sortedResults = [...results].sort((a, b) => {
    if (sortOption === "score") {
      return b.score - a.score; // Sort by score in descending order
    } else if (sortOption === "time") {
      return b.timestamp.seconds - a.timestamp.seconds; // Sort by completion time in descending order
    } else if (sortOption === "name") {
      return a.name.localeCompare(b.name); // Sort by name alphabetically
    }
    return 0; // Default order
  });

  const filteredResults = sortedResults.filter(
    (result) =>
      result.name.toLowerCase().includes(resultSearch.toLowerCase()) ||
      result.email.toLowerCase().includes(resultSearch.toLowerCase())
  );

  const fetchData = async () => {
    try {
      const participantSnapshot = await getDocs(collection(db, "participants"));
      const participantList = participantSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParticipants(participantList);

      const resultSnapshot = await getDocs(collection(db, "results"));
      const resultList = resultSnapshot.docs.map((doc) => doc.data());
      setResults(resultList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleAddParticipant = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Password tidak sama!");
      return;
    }

    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const userId = userCredential.user.uid;

      await setDoc(doc(db, "participants", userId), {
        name,
        email,
        password,
        status: "Belum Mengerjakan",
      });

      alert("Peserta berhasil ditambahkan!");
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setIsModalOpen(false);
      fetchData(); // Refresh data after adding
    } catch (error) {
      alert("Gagal menambahkan peserta. Coba lagi!");
      console.error("Error adding participant: ", error);
    }
  };

  const handleDeleteClick = (participant) => {
    setSelectedParticipant(participant);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedParticipant) return;

    try {
      // Delete from Firestore
      await deleteDoc(doc(db, "participants", selectedParticipant.id));

      // Delete from Auth
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        await deleteUser(user);
      }

      alert("Peserta berhasil dihapus!");
      setIsDeleteModalOpen(false);
      setSelectedParticipant(null);
      fetchData();
    } catch (error) {
      alert("Gagal menghapus peserta. Coba lagi!");
      console.error("Error deleting participant: ", error);
    }
  };

  return (
    <div className="w-full mx-auto p-4">
      <h1 className="text-4xl bg-primary text-white text-center font-bold rounded-xl mb-4 p-6">
        Admin
      </h1>

      {/* Modal Tambah Peserta */}
      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
          <div className="bg-white rounded-lg p-8 shadow-lg z-50">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Tambah Peserta
            </h3>
            <form onSubmit={handleAddParticipant}>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nama
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ulangi Password
                </label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
                >
                  Batal
                </button>
                <button
                  type="submit"
                  className="bg-primary text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal Konfirmasi Hapus */}
      {isDeleteModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
          <div className="bg-white rounded-lg p-8 shadow-lg z-50 max-w-md w-full">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Konfirmasi Hapus Peserta
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              Apakah Anda yakin ingin menghapus peserta dengan nama{" "}
              <span className="font-semibold">{selectedParticipant?.name}</span>
              ? Tindakan ini tidak dapat dibatalkan.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded hover:bg-gray-300"
              >
                Batal
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Tabel Status Peserta */}
      <div className="min-w-full bg-white border p-4 mb-12 border-gray-200 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Status Peserta</h2>
          <div className="flex gap-4">
            <input
              type="text"
              placeholder="Cari peserta..."
              className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              value={participantSearch}
              onChange={(e) => setParticipantSearch(e.target.value)}
            />
            <button
              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setIsModalOpen(true)}
            >
              Tambah Peserta
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr className="bg-blue-500 text-primary">
                <th className="px-4 py-2 text-left">No</th>
                <th className="px-4 py-2 text-left">Nama</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Password</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {filteredParticipants.map((participant, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">{participant.name}</td>
                  <td className="px-4 py-2">{participant.email}</td>
                  <td className="px-4 py-2">{participant.password}</td>
                  <td className="px-4 py-2">
                    <span
                      className={`inline-block px-2 py-1 text-sm font-medium rounded-full ${
                        participant.status === "Selesai"
                          ? "bg-green-600 text-white"
                          : participant.status === "Sedang Mengerjakan"
                          ? "bg-sky-200 text-yellow-600"
                          : "bg-red-200 text-red-600"
                      }`}
                    >
                      {participant.status}
                      {participant.status === "Selesai" ? (
                        <i className="fa-solid fa-check ml-2"></i>
                      ) : participant.status === "Sedang Mengerjakan" ? (
                        <i className="fa-solid fa-spinner ml-2"></i>
                      ) : (
                        <i className="fa-solid fa-xmark ml-2"></i>
                      )}
                    </span>
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleDeleteClick(participant)}
                      className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 text-sm"
                    >
                      Hapus
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Hasil Ujian */}
      <div className="bg-white shadow-md rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Hasil Ujian</h2>
          <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Cari hasil..."
              className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              value={resultSearch}
              onChange={(e) => setResultSearch(e.target.value)}
            />
            <select
              className="px-4 py-2 border rounded-lg text-secondary  focus:outline-none focus:ring-2 focus:ring-primary"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="name">Urutkan berdasarkan...</option>
              <option value="score">Nilai</option>
              <option value="time">Waktu Pengumpulan</option>
            </select>
            <button
              onClick={() => setIsTableVisible(!isTableVisible)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              title={isTableVisible ? "Sembunyikan tabel" : "Tampilkan tabel"}
            >
              {isTableVisible ? (
                <i className="fa-solid fa-eye-slash text-gray-600 text-xl"></i>
              ) : (
                <i className="fa-solid fa-eye text-gray-600 text-xl"></i>
              )}
            </button>
            <button
              onClick={() => setIsScoreVisible(!isScoreVisible)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              title={
                isScoreVisible
                  ? "Sembunyikan kolom nilai"
                  : "Tampilkan kolom nilai"
              }
            >
              {isScoreVisible ? (
                <button className="text-white bg-red-600 font-medium transition-all duration-300 py-2 px-4 rounded text-lg">
                  Tutup Nilai
                </button>
              ) : (
                <button className=" text-white bg-primary py-2 font-medium transition-all duration-300 ease-in-out px-4 rounded text-lg">
                  Lihat Nilai
                </button>
              )}
            </button>
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-500 ease-in-out ${
            isTableVisible ? "max-h-full opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
            <thead>
              <tr className="bg-blue-500 text-primary">
                <th className="px-4 py-2 text-left">No</th>
                <th className="px-4 py-2 text-left">Nama</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Jawaban Benar</th>
                <th className="px-4 py-2 text-left">Nilai</th>
                <th className="px-4 py-2 text-left">Waktu Selesai</th>
              </tr>
            </thead>
            <tbody>
              {filteredResults.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    className="px-4 py-2 text-center text-secondary font-medium"
                  >
                    Belum ada Peserta yang selesai
                  </td>
                </tr>
              ) : (
                filteredResults.map((result, index) => (
                  <tr key={index} className="border-b hover:bg-gray-100">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{result.name}</td>
                    <td className="px-4 py-2">{result.email}</td>
                    <td className="px-4 py-2">
                      {isScoreVisible ? result.correctAnswers : "x"}
                    </td>
                    <td className="px-4 py-2">
                      {isScoreVisible ? Math.round(result.score / 0.6) : "x"}
                    </td>
                    <td className="px-4 py-2">
                      {new Date(result.timestamp.seconds * 1000).toLocaleString(
                        "id-ID",
                        {
                          hour12: false,
                          timeZone: "Asia/Jakarta",
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
