import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import events from "../../data/events";
import InstagramEmbed from "./InstagramEmbed"; // Import the InstagramEmbed component
import fotoPemateri from "../../assets/img/PemateriBiner80.jpg";

const Biner80 = () => {
  const link = useLocation().pathname.split("/").pop();
  const [data, setData] = useState({
    title: "",
    detail: "",
    date: "",
  });

  useEffect(() => {
    const event = events.filter((item) => item.link === link)[0];
    setData(event);
  }, [link]);

  return (
    <main>
      {/* Konten Event */}
      <div className="flex flex-col pt-16  md:pt-20 md:mt-16 items-center ">
        <p className="text-2xl mt-6 font-bold text-center text-primary md:text-4xl">
          {data.title}
        </p>
        <p className="text-sm font-bold text-center text-secondary mt-2 md:text-lg">
          <i className="fa-regular fa-calendar mr-2" />
          {new Date(data.date).toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>
      <p className="text-center py-5 text-primary mx-2 text-sm md:text-lg">
        {data.detail}
      </p>
      <p className="text-center pb-10">
        <i>“HOW TECHNOLOGY CHANGES OUR FUTURE”</i>
      </p>
      {/* Informasi Waktu dan Tempat */}
      <div className="text-center py-5 text-primary mx-2 text-sm md:text-lg">
        <p>
          Acara pukul <strong>06.00 - 18.00 WIB</strong>
        </p>
        <p>Aula MAN 4 Aceh Besar Glee Inem</p>
        <p>Jl. Tgk Chik, Tungkop, Kecamatan Darussalam, Kabupaten Aceh Besar</p>
      </div>
      {/* Foto Pemateri */}
      <div className="mx-3 md:mx-0 ">
        <div className="flex items-center bg-white md:w-2/3 w-full justify-center mx-auto my-3 border rounded-full p-3 shadow-md">
          <img
            src={fotoPemateri}
            className="rounded-full pointer-events-none select-none"
            alt="Foto Pemateri"
            width={100}
            height={100}
          />
          <div className="flex flex-col ml-4">
            <p className="font-bold text-2xl text-primary">Pemateri</p>
            <p className="text-left md:text-center text-sm md:text-lg">
              Afnan, S.Kom., MM(T), M.Sc.,{" "}
              <span className="font-bold">Ph.D.</span>
            </p>
          </div>
        </div>

        {/* Instagram Embed */}
        <div className="w-full flex items-center justify-center">
          <InstagramEmbed
            url="https://www.instagram.com/biner8.0/"
            width={800}
            height={800}
          />
        </div>
      </div>
    </main>
  );
};

export default Biner80;
