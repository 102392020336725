const ProfilKonten = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <div className="max-w-4xl mx-auto mt-16">
      {/* Tab navigation */}
      <div className="flex flex-wrap justify-center gap-2 mb-8">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-6 py-2 rounded-full transition-all ${
              activeTab === tab.id
                ? "bg-primary text-white"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="bg-white rounded-lg p-6 shadow-sm">
        {activeTab === "sejarah" && (
          <div className=" rounded-lg shadow-sm p-6">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              Sejarah HIMATEKKOM
            </h2>
            <p className="text-justify">
              Prodi Teknik komputer berdiri pada tahun 2016, sedangkan Himpunan
              Mahasiswa Teknik Komputer (HIMATEKKOM) berdiri pada tahun 2018
              akhir, lebih tepatnya yaitu pada tanggal 3 November 2018. Sebelum
              menjadi himpunan, Teknik komputer memiliki wadah untuk mahasiswa
              yaitu Badan Pelaksana yang berlaku dari tahun 2016 sampai tahun
              2018.
            </p>
            <div>
              <h3 className="text-xl font-semibold mb-3">Ketua Himpunan</h3>
              <ul className="list-decimal list-inside space-y-2">
                <li>Teguh Rais Rahmadi</li>
                <li>Yandi Murtadha, PLT Iman Nur Hidayat</li>
                <li>M. Yusuf Kardawi</li>
                <li>Maryulis Zilal</li>
                <li>Fitra Abrar Fani</li>
                <li>Sirajul Ilmi (Saat ini)</li>
              </ul>
            </div>
          </div>
        )}

        {activeTab === "tujuan" && (
          <div className=" rounded-lg shadow-sm p-6">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              Tujuan HIMATEKKOM
            </h2>
            <ul className="list-decimal list-inside space-y-3">
              <li>Mewujudkan Tri Dharma perguruan tinggi.</li>
              <li>
                Ikut serta mengusahakan tujuan pendidikan untuk membentuk
                sarjana yang berbudi pekerti, cerdas, mandiri, berwawasan luas,
                demokratis, dan bertanggung jawab.
              </li>
              <li>
                Memberikan dorongan kepada mahasiswa untuk menjadi pemimpin dan
                penggerak dalam kehidupan berbangsa, bernegara serta beragama.
              </li>
              <li>
                Ikut serta menyumbangkan karya dan pikiran dalam penataan
                kehidupan masyarakat.
              </li>
              <li>
                Mewujudkan persatuan dan kesatuan mahasiswa Teknik Komputer di
                Internal dan Eksternal Program Studi Teknik Komputer.
              </li>
              <li>
                Ikut serta dalam membantu program kerja program studi Teknik
                Komputer.
              </li>
            </ul>
          </div>
        )}

        {activeTab === "fungsi" && (
          <div className=" rounded-lg shadow-sm p-6">
            <h2 className="text-xl md:text-2xl font-bold mb-4">
              Fungsi HIMATEKKOM
            </h2>
            <ul className="list-decimal list-inside space-y-3">
              <li>
                Menampung dan menyalurkan aspirasi minat dan bakat mahasiswa
                Teknik Komputer.
              </li>
              <li>
                Wadah pembinaan kreatifitas dan inovasi mahasiswa Teknik
                Komputer.
              </li>
              <li>
                Sebagai wadah pengembangan keahlian mahasiswa Teknik Komputer di
                bidang knowledge, soft skill, dan hardskill.
              </li>
            </ul>
          </div>
        )}

        {activeTab === "visi-misi" && (
          <div className="space-y-6">
            <div className=" rounded-lg shadow-sm p-6">
              <h2 className="text-xl md:text-2xl font-bold mb-4">
                Visi HIMATEKKOM 2024
              </h2>
              <p className="text-center md:text-justify">
                Menjadikan Himpunan Mahasiswa Teknik Komputer sebagai wadah
                pengembangan potensi dan kesejahteraan anggota, serta
                meningkatkan eksistensi HIMATEKKOM yang memberikan dampak
                positif bagi mahasiswa dan lingkungan sekitar yang berazaskan
                kekeluargaan.
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl md:text-2xl font-bold mb-4">
                Misi HIMATEKKOM 2024
              </h2>
              <ul className="list-decimal list-inside space-y-3">
                <li>
                  Mengembangkan Potensi Anggota yang Sejalan dengan Haluan
                  Himpunan
                </li>
                <li>
                  Peningkatan Kesejahteraan Anggota Melalui Fasilitas dan
                  Layanan Himpunan
                </li>
                <li>
                  Peningkatan Kualitas Program Kerja untuk Demi Mendukung
                  Pertumbuhan HIMATEKKOM
                </li>
                <li>
                  Mempererat Tali Persaudaraan Demi Mewujudkan Rasa Kekeluargaan
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilKonten;
