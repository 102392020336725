import React, { useState, useEffect } from "react";

const Layanan = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <main className="flex justify-center items-start pt-32">
      <div className="bg-white shadow-md rounded-lg p-10 text-left mx-6 md:mx-0">
        <h1 className="text-2xl font-bold text-primary mb-3 text-center">
          Layanan HIMATEKKOM
        </h1>

        <h6 className="text-xl font-bold text-primary mb-8 text-center">
          Hubungi Kami
        </h6>

        <div className="text-lg text-secondary">
          <p className="flex items-center mb-6">
            <i className="fas fa-envelope mr-3 text-blue-500 text-2xl" />
            <a
              href={
                isMobile
                  ? "mailto:himatekkom.ft@usk.ac.id"
                  : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=himatekkom.ft@usk.ac.id"
              }
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              himatekkom.ft@usk.ac.id
            </a>
          </p>
          <p className="flex items-center mb-6">
            <i className="fab fa-whatsapp mr-3 text-blue-500 text-2xl" />
            <a
              href="https://wa.me/6281534086508"
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              0815-3408-6508 Arie Irawan <b>Sekretaris Umum</b>
            </a>
          </p>
          <p className="flex items-center mb-6">
            <i className="fab fa-whatsapp mr-3 text-blue-500 text-2xl" />
            <a
              href="https://wa.me/6281939267649"
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              0819-3926-7649 Aditya Yufnanda <b>HUMAS</b>
            </a>
          </p>
        </div>
      </div>
    </main>
  );
};

export default Layanan;
